<template>
  <div
    ref="scrollContainer"
    class="text-center overflow-y-auto relative"
    :style="scrollContainerStyle"
    @scroll="handleScroll"
  >
    <!-- Slot for custom content -->
    <slot></slot>
    <!-- Loading Indicator -->
    <div class="w-full py-4">
      <LoadingSpinner class="ml-auto mr-auto" v-if="isLoading" />
    </div>
    <!-- Error Message -->
    <div v-if="errorMessage" class="w-auto p-4 bg-red-600 text-white rounded-md">
      <p>{{ errorMessage }}</p>
      <a @click="recoverFromError()" class="text-white hover: cursor-pointer underline">Try Again</a>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

export default {
  name: 'InfiniteScroll',
  props: {
    bottomOffset: {
      type: Number,
      default: 100
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    errorMessage: {
      type: [String, null],
      default: null
    },
    // Mainly used as a flag when the parent determines all data possible has been retrieved
    preventLoad: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoadingSpinner
  },
  data() {
    return {
      debounceLoadMore: debounce(() => {
        this.$emit('loadMore');
      }, 500)
    };
  },
  // Emits the loadMore event when the component is created to fetch initial data.
  mounted() {
    this.debounceLoadMore();
  },
  computed: {
    // Allows the scroll container to be styled with the height and width props.
    scrollContainerStyle() {
      return {
        height: this.height.toString().includes('%') ? this.height : `${this.height}px`,
        width: this.width.toString().includes('%') ? this.width : `${this.width}px`,
        overflowY: 'auto'
      };
    }
  },
  methods: {
    handleScroll() {
      // Calculate the distance from the bottom of the scroll container
      const scrollContainer = this.$refs.scrollContainer;
      const scrollTop = scrollContainer.scrollTop;
      const containerHeight = scrollContainer.clientHeight;
      const scrollHeight = scrollContainer.scrollHeight;

      const bottomDistance = scrollHeight - scrollTop - containerHeight;

      // Disable loadMore if the component is already loading or if there is an error message
      if (bottomDistance < this.bottomOffset && !this.isLoading && !this.errorMessage && !this.preventLoad) {
        this.debounceLoadMore();
      }
    },
    // Emits the recoverFromError event.
    recoverFromError() {
      this.$emit('recoverFromError');
    }
  }
};
</script>
