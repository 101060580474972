import Axios from '@/axios';

const commentsUrl = `/comment-service/v2/comments`;

const createComment = async (body) => {
  const response = await Axios.post(commentsUrl, body);

  return response;
};

const listComments = async (params = {}) => {
  const response = await Axios.get(commentsUrl, {
    params
  });

  return response.data.data;
};

export default { createComment, listComments };
