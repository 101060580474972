<template>
  <div class="relative">
    <textarea
      v-model="textContent"
      ref="textarea"
      rows="1"
      @input="adjustHeight"
      @keydown.enter.prevent="submitText"
      class="border rounded border-gray-300 min-h-11 px-4 py-4 w-full pr-16 overflow-hidden"
      placeholder="Add a comment..."
      style="resize: none"
    ></textarea>
    <div
      :class="{
        'hover: border-round hover: border hover: border-gray-300 hover: bg-gray-50': !isSendButtonDisabled
      }"
      class="absolute rounded bottom-3.5 right-2 w-10 h-10 flex items-center justify-center"
    >
      <button
        @click="submitText"
        :disabled="isSendButtonDisabled"
        class="flex items-center justify-center rounded w-5 overflow-hidden"
      >
        <font-awesome-icon class="text-lg text-gray-500" :icon="['far', 'paper-plane-top']" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  data() {
    return {
      textContent: ''
    };
  },
  computed: {
    isSendButtonDisabled() {
      return !this.textContent || !this.textContent.trim();
    }
  },
  methods: {
    adjustHeight() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
    },
    submitText() {
      if (!this.isSendButtonDisabled) {
        this.$emit('submit', this.textContent);
        this.textContent = '';
        this.$nextTick(() => {
          this.$refs.textarea.style.height = 'auto';
          this.$refs.textarea.rows = 1;
        });
      }
    }
  }
};
</script>
