import Axios from '@/axios';

const getOrderHistory = async (orderId, params) => {
  const response = await Axios.get(`/order-service/v2/orders/${orderId}/history`, {
    params
  });

  return response.data.data;
};

// Add other history api requests to this file

export default { getOrderHistory };
