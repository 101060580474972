<template>
  <div class="border-t-2 py-4">
    <div class="flex">
      <p class="font-bold">{{ comment.userName }}</p>
      <p :id="comment.id" class="text-gray-500 ml-2">{{ createdAt }}</p>
      <b-tooltip :target="comment.id" triggers="hover">
        {{
          formatDateTime(parseISO(comment.createdAt), {
            format: 'dateTime',
            timeZone: sessionUserTimeZone,
            displayTimeZone: true
          })
        }}
      </b-tooltip>
    </div>
    <p class="mt-2 text-left">{{ comment.content }}</p>
  </div>
</template>

<script>
import { formatDistance } from 'date-fns';

export default {
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  computed: {
    createdAt() {
      const now = new Date();
      const created = new Date(this.comment.createdAt);

      const timeAgo = formatDistance(created, now) + ' ago';
      return timeAgo;
    }
  }
};
</script>
