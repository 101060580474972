<template>
  <b-container class="mb-7">
    <h1>Order #{{ id }}</h1>
    <ViewOrderV2 @voidSuccess="showSuccess" :orderId="orderId"></ViewOrderV2>
  </b-container>
</template>

<script>
import ViewOrderV2 from '@/components/ui/ViewOrderV2.vue';

export default {
  components: {
    ViewOrderV2
  },
  created() {
    this.orderId = this.$route.params.id;
    this.id = this.formatUuid(this.orderId);
  },
  data() {
    return {
      orderComment: '',
      orderId: '',
      id: '',
      alert: {
        text: '',
        variant: '',
        model: false
      }
    };
  },
  methods: {
    showSuccess(message) {
      this.alert.text = message;
      this.alert.model = true;
      this.alert.variant = 'success';
    }
  }
};
</script>

<style scoped></style>
